import { slick } from 'slick-carousel-latest';

document.addEventListener('DOMContentLoaded', (e) => {

	const blocks = document.querySelectorAll('.block--featured-slider');

	const block_featured_slider = {
		
		init: (block) => {
			
			const image = '#' + block.id + ' .featured__image-slider';
			const text = '#' + block.id + ' .featured__text-slider';
			const items = document.querySelectorAll(text + ' .slide');
			
			const options = { 
				text: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: true,
					dots: true,
					appendDots: '#' + block.id + ' .slick-slider-nav .dots',
					appendArrows: '#' + block.id + ' .slick-slider-nav .arrows',
					asNavFor: image,
                    nextArrow: '<button type="button" class="next"><svg class="icon" aria-hidden="true" focusable="false" viewBox="0 0 320 512"><use xlink:href="#arrow-right"></use></svg></button>',
				},
				
				image: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    fade: true,
                    asNavFor: text
				}
			}
			
			if(items.length > 1) {
				$(text).slick(options.text);
				$(image).slick(options.image);
			}
		},
		
	}

	blocks.forEach( block => {
		block_featured_slider.init(block)
	});

	// scroll-triggered block
    let blockFn = (entries) => entries.forEach(entry => {
        if (entry.isIntersecting) {
            $(entry.target).addClass('animate');
        }
    });
	const config = { rootMargin: '0px 0px',	threshold: .2 };
    let observer = new IntersectionObserver(blockFn, config);
    blocks.forEach(el => observer.observe(el));

});